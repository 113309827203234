import React from "react";

const Privacy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>

      <p>This Privacy Policy describes how LogisticChaos Pty Ltd ("Company", "we", "us", or "our") collects, uses, shares, and protects user information obtained through the iOS application ("App").</p>

      <p>The terms "you," "your," and "yours" refer to the user/customer utilizing our App. By using the App, you consent to the data practices described in this statement.</p>

      <h2>1. Information Collection and Use</h2>

      <p>Our App may collect personally identifiable information, such as your name, email address, and other relevant user data for application functioning and improving the user experience.</p>

      <p>We use an external Large Language Model (LLM) platform named OpenAI for processing natural language data inputs to provide, maintain, and improve our services. The processed data may include your search queries and other inputs you provide while using our App.</p>

      <h2>2. Children's Privacy</h2>

      <p>Our service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13. If we discover that a child under 13 has provided us with Personal Information, we will promptly delete this from our servers.</p>

      <h2>3. Third-Party Services</h2>

      <p>We may employ third-party companies and individuals due to the following reasons: to facilitate our Service; provide the Service on our behalf; perform Service-related services; assist us in analyzing how our Service is used.</p>

      <p>Please note that these third parties have access to your Personal Information to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

      <h2>4. OpenAI’s Large Language Model (LLM) Privacy</h2>

      <p>We utilize the services of OpenAI's Large Language Model (LLM) for natural language processing tasks. The LLM does not have the ability to access or retrieve personal data unless expressly provided by the user in the context of the conversation. The data sent to OpenAI's LLM is processed and used to generate responses and is not stored or used for further training purposes.</p>

      <h2>5. Security of Information</h2>

      <p>We value your trust in providing us with your Personal Information, thus we strive to use commercially acceptable means of protecting it. However, no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>

      <h2>6. Changes to This Privacy Policy</h2>

      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Changes to this Privacy Policy are effective when they are posted on this page.</p>

      <h2>7. Contact Us</h2>

      <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at [Company's Contact Information].</p>

      <p>Last updated: 06 August 2023</p>

    </div>
  );
};

export default Privacy;
